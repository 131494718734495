import Vue3Toastify, { toast } from 'vue3-toastify'
import 'vue3-toastify/dist/index.css'

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(Vue3Toastify, {
    autoClose: 10000,
    theme: 'colored',
    position: toast.POSITION.TOP_CENTER,
  })

  return {
    provide: { toast },
  }
})
